import Login from '@/components/Login'
import SubscribeLayer from '@/components/SubscribeLayer'
import { DefaultAvatar } from '@/constants'
import { getUserInfo } from '@/service/api'
import {
  addUserInfo,
  changeIsGuestStatus,
  loginUserInfo,
  setLoadingStatus,
} from '@/store/loginUserInfo'
import { Avatar, Menu } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import GuideDialog from '../Guide'

export default function Header() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userInfo, resgisterSuccess, showSubscribeLayer } = useSelector(loginUserInfo)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const getUser = async () => {
    const token = localStorage.getItem('TOKEN')
    if (!token) return
    const res = await getUserInfo()
    const { data } = res || {}
    if (data) {
      const { user, imsig } = data
      dispatch(addUserInfo({ ...user, userSig: imsig }))
      dispatch(changeIsGuestStatus(false))
    } else {
      dispatch(changeIsGuestStatus(true))
    }
    dispatch(setLoadingStatus(false))
  }
  const logout = () => {
    localStorage.removeItem('TOKEN')
    dispatch(addUserInfo({}))
    dispatch(changeIsGuestStatus(true))
    handleMenuClose()
    navigate('/')
    if (typeof window.google !== 'undefined') {
      google.accounts.id.disableAutoSelect()
    }
  }
  useEffect(() => {
    const token = localStorage.getItem('TOKEN')
    if (token) {
      getUser()
    } else {
      dispatch(setLoadingStatus(false))
    }
  }, [])

  return (
    <>
      <header className="bg-[#131313] p-4 mb-4 w-full flex justify-between items-center fixed top-0 left-0 z-50 border-[#363636] border-b text-white">
        <div className="flex items-center cursor-pointer" onClick={() => navigate('/')}>
          <svg
            className="h-8 w-8 mr-2"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2L2 7L12 12L22 7L12 2Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 17L12 22L22 17"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 12L12 17L22 12"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h1 className="text-xl font-bold">
            Vibbe<span className="text-pink-500">.AI</span>
          </h1>
        </div>
        {Object.keys(userInfo).length > 0 ? (
          <div className="flex items-center space-x-2 cursor-pointer">
            <Avatar
              src={userInfo.avatar || DefaultAvatar}
              style={{ cursor: 'pointer' }}
              onClick={handleMenuOpen}
            />
            <div onClick={handleMenuOpen} className="hidden lg:flex lg:items-center">
              <span className="ml-2 text-sm font-semibold leading-6 text-white" aria-hidden="true">
                My Profile
              </span>
              <svg
                className="ml-2 h-5 w-5 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              onMouseLeave={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              sx={{
                '& .MuiMenu-list': {
                  backgroundColor: '#131313',
                  color: 'white',
                },
              }}
            >
              <a
                className="h-11 px-4 py-2.5 bg-zinc-900 hover:bg-gray-700 justify-start items-center gap-2 flex"
                onClick={() => navigate('/settings')}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.6" clip-path="url(#clip0_3616_4966)">
                    <path
                      d="M23.7818 8.64499L18.2678 3.13099C18.1283 2.99149 17.9401 2.91274 17.7436 2.91124L6.35933 2.82424C6.15833 2.82274 5.96558 2.90149 5.82308 3.04399L0.221328 8.64574C-0.0711719 8.93899 -0.0711719 9.41299 0.221328 9.70624L11.4713 20.9562C11.7646 21.2495 12.2393 21.2495 12.5318 20.9562L23.7818 9.70624C24.0751 9.41299 24.0751 8.93824 23.7818 8.64499ZM19.4318 9.71149L14.0678 15.1287C13.7761 15.4235 13.3013 15.4257 13.0066 15.134C12.7118 14.8422 12.7096 14.3675 13.0013 14.0727L17.8576 9.16999L16.2166 7.42099C15.9293 7.12324 15.9383 6.64774 16.2368 6.36049C16.3823 6.21949 16.5788 6.14524 16.7806 6.15124C16.9831 6.15724 17.1743 6.24499 17.3108 6.39424L19.4468 8.67199C19.7213 8.96599 19.7153 9.42499 19.4318 9.71149Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3616_4966">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="text-sm font-semibold font-['Poppins'] text-white">
                  Subscription
                </span>
              </a>
              <a
                className="h-11 px-4 py-2.5 bg-zinc-900 hover:bg-gray-700 justify-start items-center gap-2 flex"
                href="/profile"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3128_4961)">
                    <path
                      d="M18.975 8.95L17.0812 7.05625V4.375C17.0805 3.98567 16.9256 3.61247 16.6503 3.33717C16.375 3.06187 16.0018 2.90691 15.6125 2.90625H12.9312L11.0375 1.0125C10.7613 0.739223 10.3885 0.585937 9.99995 0.585938C9.61143 0.585938 9.23861 0.739223 8.96245 1.0125L7.0687 2.90625H4.38745C3.99812 2.90691 3.62492 3.06187 3.34962 3.33717C3.07432 3.61247 2.91936 3.98567 2.9187 4.375V7.05625L1.02495 8.95C0.888287 9.08601 0.779841 9.24769 0.705842 9.42573C0.631843 9.60378 0.59375 9.79469 0.59375 9.9875C0.59375 10.1803 0.631843 10.3712 0.705842 10.5493C0.779841 10.7273 0.888287 10.889 1.02495 11.025L2.9187 12.925V15.6C2.91845 15.7929 2.95628 15.9841 3.03 16.1624C3.10372 16.3407 3.2119 16.5027 3.34834 16.6391C3.48477 16.7755 3.64678 16.8837 3.82509 16.9575C4.0034 17.0312 4.1945 17.069 4.38745 17.0688H7.0687L8.96245 18.9625C9.09846 19.0992 9.26013 19.2076 9.43818 19.2816C9.61623 19.3556 9.80714 19.3937 9.99995 19.3937C10.1928 19.3937 10.3837 19.3556 10.5617 19.2816C10.7398 19.2076 10.9014 19.0992 11.0375 18.9625L12.9312 17.0688H15.6125C15.8054 17.069 15.9965 17.0312 16.1748 16.9575C16.3531 16.8837 16.5151 16.7755 16.6516 16.6391C16.788 16.5027 16.8962 16.3407 16.9699 16.1624C17.0436 15.9841 17.0814 15.7929 17.0812 15.6V12.925L18.975 11.025C19.1116 10.889 19.2201 10.7273 19.2941 10.5493C19.3681 10.3712 19.4062 10.1803 19.4062 9.9875C19.4062 9.79469 19.3681 9.60378 19.2941 9.42573C19.2201 9.24769 19.1116 9.08601 18.975 8.95ZM9.99995 13.125C9.38188 13.125 8.7777 12.9417 8.26379 12.5983C7.74989 12.255 7.34935 11.7669 7.11283 11.1959C6.8763 10.6249 6.81442 9.99653 6.935 9.39034C7.05558 8.78415 7.3532 8.22733 7.79024 7.79029C8.22728 7.35325 8.7841 7.05563 9.39029 6.93505C9.99648 6.81447 10.6248 6.87635 11.1958 7.11288C11.7669 7.3494 12.2549 7.74994 12.5983 8.26384C12.9417 8.77775 13.125 9.38193 13.125 10C13.125 10.8288 12.7957 11.6237 12.2097 12.2097C11.6236 12.7958 10.8288 13.125 9.99995 13.125Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3128_4961">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="text-sm font-semibold font-['Poppins'] text-white">Settings</span>
              </a>
              <a
                className="h-11 px-4 py-2.5 bg-zinc-900 hover:bg-gray-700 justify-start items-center gap-2 flex"
                onClick={logout}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3128_4973)">
                    <path
                      d="M10.0155 0C15.2319 0 19.51 3.99453 19.9701 9.09085H11.8338V6.36358C11.8338 5.99637 11.6119 5.66462 11.2728 5.52363C10.9329 5.3828 10.5419 5.46087 10.282 5.72088L6.64554 9.35736C6.29015 9.71275 6.29015 10.2872 6.64554 10.6428L10.282 14.2791C10.5419 14.5391 10.9329 14.6173 11.2728 14.4763C11.6119 14.3355 11.8338 14.0036 11.8338 13.6364V10.9091H19.9701C19.51 16.0054 15.2319 19.9999 10.0155 19.9999C4.49289 19.9999 0.015625 15.5227 0.015625 10.0001C0.015625 4.47726 4.49289 0 10.0155 0Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3128_4973">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="text-sm font-semibold font-['Poppins'] text-white">Logout</span>
              </a>
            </Menu>
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <Login />
          </div>
        )}
        {resgisterSuccess && <GuideDialog />}
        {showSubscribeLayer && <SubscribeLayer />}
      </header>
    </>
  )
}
