import { configureStore } from '@reduxjs/toolkit'
import loginUserInfo from './loginUserInfo'
import recommend from './recommend'

export default configureStore({
  reducer: {
    loginUserInfo,
    recommend,
  },
})
