import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogContent, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'

// 自定义样式的Dialog
const BlurDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(28, 28, 28, 0.95)',
    borderRadius: '16px',
    padding: theme.spacing(1),
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(8px)', // 给对话框背后的内容也添加模糊效果
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}))

const UpgradeDialog = (props) => {
  const [open, setOpen] = useState(true)

  const features = [
    'Unlimited text messages',
    'Passion model',
    'Up to 8K Long-Term memory',
    'Create your own characters',
    'Ad-free',
  ]

  return (
    <BlurDialog
      open={open}
      maxWidth="md"
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          backgroundColor: 'rgba(28, 28, 28, 0.95)',
          borderRadius: '16px',
          overflow: 'hidden',
        },
      }}
    >
      <IconButton
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'white',
          zIndex: 1,
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent className="flex p-0 max-w-[730px]">
        {/* 左侧图片 */}
        <div className="mr-8">
          <img
            src="https://cdn.candy.ai/cdn-cgi/image/format=webp,qua…ai/148797895-0db87468-c0ed-40a3-9a7c-8e1058d594f9"
            alt="Upgrade"
            className="w-[152px] h-[229px] md:static md:w-[307px] md:h-[462px] object-cover rounded-2xl border-2 md:block z-20 border-[#6B3340]"
          />
        </div>

        {/* 右侧内容 */}
        <div className="flex-1 p-8">
          <div className="flex items-center gap-2 mb-4">
            <h2 className="text-2xl font-[Poppins] text-white">Upgrade to Unlock</h2>
            <i className="iconfont text-white !text-2xl">&#xe61d;</i>
          </div>

          <h3 className="font-medium text-xl text-pink-500 mb-6">Unlimited Messages</h3>

          <div className="text-gray-400 mb-4">With subscription you get access to:</div>

          <div className="space-y-4 mb-6">
            {features.map((feature, index) => (
              <div key={index} className="flex items-center gap-3">
                <div className="w-4 h-4 flex items-center justify-center bg-pink-600 rounded-full">
                  <CheckIcon sx={{ fontSize: 12, color: '#fff' }} />
                </div>
                <span className="text-white">{feature}</span>
              </div>
            ))}
          </div>

          <Button
            variant="contained"
            fullWidth
            className="bg-pink-600 hover:bg-pink-700 mt-8 py-3 text-lg font-semibold"
            onClick={() => setOpen(false)}
          >
            Upgrade
          </Button>
        </div>
      </DialogContent>
    </BlurDialog>
  )
}

export default UpgradeDialog
