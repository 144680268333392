import { TextField } from '@mui/material'
import { forwardRef, useImperativeHandle, useState } from 'react'

const PasswordField = forwardRef((props, ref) => {
  const { placeholder } = props
  useImperativeHandle(ref, () => ({
    getValue,
  }))
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordWarn, setPasswordWarn] = useState('')
  const getValue = () => {
    if (!password) {
      setPasswordWarn('Please enter your password')
      return
    }
    return password
  }
  return (
    <div className="relative">
      <span
        onClick={() => setShowPassword(!showPassword)}
        className="absolute right-4 top-4 cursor-pointer z-10"
      >
        {!showPassword ? (
          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path
              d="M512 637.207c-171.753 0-343.273-52.363-465.455-156.858a34.91 34.91 0 1 1 46.546-53.062c215.505 186.182 623.244 186.182 837.818 0a34.91 34.91 0 0 1 46.546 53.062C855.273 584.844 683.753 637.207 512 637.207z"
              fill="#666"
            />
            <path
              d="M384.465 791.273a28.625 28.625 0 0 1-6.283 0 34.676 34.676 0 0 1-29.091-42.124l23.273-137.309a34.91 34.91 0 1 1 69.818 12.102L418.909 761.25a34.91 34.91 0 0 1-34.444 30.022zm523.171-69.818a34.444 34.444 0 0 1-24.669-10.473L768.233 594.618a34.91 34.91 0 1 1 49.57-48.873L932.539 662.11a34.91 34.91 0 0 1 0 49.338 36.305 36.305 0 0 1-24.902 10.008zm-791.272 0a36.305 36.305 0 0 1-23.273-10.008 34.91 34.91 0 0 1 0-49.338l114.734-116.364A34.91 34.91 0 1 1 256 594.618L141.265 710.982a34.444 34.444 0 0 1-24.901 10.473zm524.8 69.818a34.91 34.91 0 0 1-34.211-28.858l-23.273-137.31a34.91 34.91 0 1 1 67.956-13.265l23.273 137.31a34.676 34.676 0 0 1-28.16 40.494 28.625 28.625 0 0 1-5.585 1.629z"
              fill="#666"
            />
          </svg>
        ) : (
          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <path
              d="M512 256c-256 0-512 256-512 256s256 256 512 256 512-256 512-256-256-256-512-256zm0 448c-106 0-192-86-192-192s86-192 192-192 192 86 192 192-86 192-192 192zm0-320c-70.7 0-128 57.3-128 128s57.3 128 128 128 128-57.3 128-128-57.3-128-128-128z"
              fill="#666"
            />
          </svg>
        )}
      </span>
      <TextField
        fullWidth
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder || 'Password'}
        variant="outlined"
        onChange={(e) => setPassword(e.target.value)}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '8px',
            '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.1)' },
          },
          '& input': { color: 'white' },
        }}
      />
      <p className={`text-red-500 text-sm invisible mt-2 ${passwordWarn ? '!visible' : ''}`}>
        {passwordWarn}
      </p>
    </div>
  )
})

export default PasswordField
