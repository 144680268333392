import dayjs from 'dayjs'

const getDaysDifference = (time) => {
  const currentDate = dayjs() // 获取当前日期
  const targetDate = dayjs(time * 1000) // 转换为 dayjs 对象
  return currentDate.diff(targetDate, 'day') // 计算与今天的差距，单位为天
}

const addLastDayFlag = (messages) => {
  const last = messages[messages.length - 1]
  if (!last) return messages
  const lastDayDifference = getDaysDifference(last.time) // 计算与今天的差距
  if (lastDayDifference > 0) {
    last.lastDay = `${lastDayDifference} day ago`
  }
  return messages
}

export default addLastDayFlag
