import { createSlice } from '@reduxjs/toolkit'
// import { updateLocalUserInfo } from '@/common/utils'

const initialState = {
  list: [],
}

export const recommendSlice = createSlice({
  name: 'recommend',
  initialState,
  reducers: {
    addList: (state, action) => {
      state.list = action.payload
    },
  },
})

export const recommendList = (state) => state.recommend.list

export const { addList } = recommendSlice.actions

export default recommendSlice.reducer
