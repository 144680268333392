import ageIcon from '@/assets/resource/age.svg'
import occupationIcon from '@/assets/resource/occupation.svg'
import shipIcon from '@/assets/resource/ship.svg'
import Header from '@/components/Header'
import Sidebar from '@/components/Sidebar'
import { SDKAppID } from '@/constants'
import { getChatPartnerInfo } from '@/service/api'
import { loginUserInfo, setShowLogin } from '@/store/loginUserInfo'
import { calculateAge } from '@/utils'
import addLastDayFlag from '@/utils/countDiffDay'
import { Send } from '@mui/icons-material'
import { Avatar, Box, IconButton, TextField, Typography } from '@mui/material'
import TencentCloudChat from '@tencentcloud/chat'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from './index.module.css'
import MsgList from './msgList'

let ChatSdk = null
let sendInterval = 0

const ChatRoom = () => {
  const { userInfo, isGuest } = useSelector(loginUserInfo)
  const { characterId, chatId } = useParams()
  const [msgVal, setMsgVal] = useState('')
  const [messageList, setMessageList] = useState([])
  const [activeUid, setActiveUid] = useState(chatId)
  const msgCompleted = useRef(false)
  const [conversationList, setConversationList] = useState([])
  const [nextReqMessageID, setNextReqMessageID] = useState('')
  const inputFocus = useRef(false)
  const [partnerInfo, setPartnerInfo] = useState({})
  const dispatch = useDispatch()

  const formatMessage = (list) => {
    const { chat_id } = userInfo
    return list.map((item) => {
      const msgItem = {
        id: item.ID,
        text: item.payload?.text,
        action: item.from === String(chat_id) ? 'send' : 'reply',
        time: item?.time,
      }
      if (item.type === TencentCloudChat.TYPES.MSG_TEXT) {
        msgItem.msgType = 'text'
      }
      if (item.type === TencentCloudChat.TYPES.MSG_IMAGE) {
        msgItem.msgType = 'image'
      }
      return msgItem
    })
  }

  const getHisotyMessage = async (id) => {
    if (msgCompleted.current) {
      return
    }
    const params = {
      conversationID: `C2C${chatId}`,
      count: 15,
    }

    if (nextReqMessageID) {
      params.nextReqMessageID = nextReqMessageID
    }
    try {
      let messageRes = await ChatSdk.getMessageList(params)
      console.log('messageRes', messageRes)
      const { code, data } = messageRes || {}
      if (data && code === 0) {
        const { messageList, nextReqMessageID, isCompleted } = data
        msgCompleted.current = isCompleted
        if (nextReqMessageID) {
          setNextReqMessageID(nextReqMessageID)
        }

        const usedList = addLastDayFlag(formatMessage(messageList))
        setMessageList((prev) => [...usedList, ...prev])
      }
    } catch (error) {
      console.log('getMessageList error', error)
    }
  }
  const getConversationList = async (event) => {
    const conversationList = event.data
    console.log('conversationList', conversationList)
    if (conversationList.length) {
      const currentIndex = conversationList.findIndex(
        (item) => item.conversationID === `C2C${activeUid}`,
      )
      if (currentIndex > -1 && currentIndex !== 0) {
        const deleteItem = conversationList.splice(currentIndex, 1)
        conversationList.unshift(deleteItem)
      } else if (currentIndex === -1) {
        const newItem = {
          conversationID: `C2C${activeUid}`,
          userProfile: {
            userID: activeUid,
            nick: 'Leonie',
          },
        }
        conversationList.unshift(newItem)
      }
      setConversationList(conversationList)
      getHisotyMessage(activeUid)
    }
  }

  useEffect(() => {
    const init = async () => {
      if (!isGuest) {
        ChatSdk = TencentCloudChat.create({ SDKAppID: SDKAppID })
        const { chat_id, userSig } = userInfo
        try {
          const loginRes = await ChatSdk.login({ userID: String(chat_id), userSig })
          console.log('loginRes', loginRes)
        } catch (error) {
          console.log('login error', error)
        }

        ChatSdk.on(TencentCloudChat.EVENT.SDK_READY, () => {
          let promise = ChatSdk.getConversationList()
          promise.then((res) => getConversationList(res))
        })
        ChatSdk.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, (event) => {
          console.log('message received:', event)
          const { data } = event
          if (Array.isArray(data)) {
            const usedList = formatMessage(data)
            setMessageList((prev) => [...prev, ...usedList])
          }
        })

        ChatSdk.on(TencentCloudChat.EVENT.CONVERSATION_LIST_UPDATED, getConversationList)
      }
      return () => {
        ChatSdk.destroy()
      }
    }
    init()
  }, [userInfo])

  useEffect(() => {
    getChatPartnerInfo({
      character_id: characterId,
    }).then((res) => setPartnerInfo(res?.data))
  }, [characterId])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter' && inputFocus.current) {
        handleSendMessage()
      }
    }
    window.removeEventListener('keydown', handleKeyPress)
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [msgVal])

  const handleSendMessage = () => {
    if (isGuest) {
      dispatch(setShowLogin(true))
      return
    }
    if (!msgVal) {
      return
    }
    if (Date.now() - sendInterval < 1500) {
      return showToast('You typed too fast...')
    }
    let sendInfo = ChatSdk.createTextMessage({
      to: activeUid,
      conversationType: TencentCloudChat.TYPES.CONV_C2C,
      payload: {
        text: msgVal,
      },
    })
    let promise = ChatSdk.sendMessage(sendInfo)
    promise.then((res) => {
      console.log('send message success:', res)
      const { message } = res.data
      if (message.type === TencentCloudChat.TYPES.MSG_TEXT) {
        setMessageList((prev) => [
          ...prev,
          { id: message?.ID, text: msgVal, action: 'send', msgType: 'text' },
        ])
      }
      setMsgVal('')
      sendInterval = Date.now()
    })
  }

  const onScroll = (e) => {
    if (msgCompleted.current) {
      return
    }
    const { scrollTop } = e.target
    if (scrollTop === 0) {
      getHisotyMessage(activeUid)
    }
  }

  useEffect(() => {
    if (ChatSdk) {
      msgCompleted.current = false
      setNextReqMessageID('')
      getHisotyMessage(activeUid)
    }
  }, [activeUid])

  const formatLastTime = (time) => {
    if (!time) {
      return ''
    }
    const now = dayjs()
    const lastTime = dayjs(time * 1000)
    const diff = now.diff(lastTime, 'day')
    if (diff === 0) {
      return lastTime.format('HH:mm')
    }
    return lastTime.format('YY/MM/DD')
  }
  const clearHistoryMessage = (e) => {
    e.stopPropagation()
    ChatSdk.clearHistoryMessage(`C2C${activeUid}`)
    setMessageList([])
  }
  const deleteConversation = (e) => {
    e.stopPropagation()
    ChatSdk.deleteConversation(`C2C${activeUid}`)
    const deleteIndex = conversationList.findIndex(
      (item) => item.conversationID === `C2C${activeUid}`,
    )
    if (deleteIndex !== -1) {
      const item = conversationList.splice(deleteIndex, 1)
      if (item.userProfile?.userID === activeUid) {
        setMessageList([])
      }
    }
    setConversationList(conversationList)
  }

  return (
    <>
      <Header />
      <Box className="flex h-screen bg-[#131313] text-gray-100 text-left pt-20">
        {/* Sidebar */}
        <Sidebar isMobile={true} from="chat" />
        <Box className="p-6 bg-[#1A1A1A] flex flex-col space-y-4 w-80">
          <Typography variant="h6" className="text-white">
            Chat
          </Typography>
          <Box className="overflow-y-auto space-y-4">
            {conversationList.map((item) => (
              <Box
                key={item.conversationID}
                className={`${styles.userItem} ${activeUid === item.userProfile?.userID ? 'border-[#db2777]' : 'border-[#303030]'}`}
                onClick={() => {
                  setActiveUid(item.userProfile?.userID)
                }}
              >
                <Box className="flex items-center relative">
                  <Avatar src="https://via.placeholder.com/40" />
                  {item.unreadCount > 0 && (
                    <div className={styles.unreadCount}>{item.unreadCount}</div>
                  )}
                  <Box className="flex-col ml-3 w-36 justify-start items-start">
                    <Typography className="text-white text-xs">{item.userProfile?.nick}</Typography>
                    <Typography className="text-gray-400 truncate">
                      {item.lastMessage?.payload?.text}
                    </Typography>
                  </Box>
                </Box>
                <Box className="flex flex-col justify-between h-11 ml-2">
                  <Box className="text-xs">{formatLastTime(item.lastMessage?.lastTime)}</Box>
                  <Box className={styles.operation}>
                    <i onClick={clearHistoryMessage} className="iconfont !text-xs mr-2">
                      &#xe61c;
                    </i>
                    <i onClick={deleteConversation} className="iconfont !text-xs">
                      &#xe66d;
                    </i>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Chat Section */}
        <Box className="flex-grow flex flex-col">
          {/* Chat Header */}
          <Box className="bg-[#131313] p-4 flex justify-between items-center">
            <Box className="flex items-center space-x-3">
              <Avatar src={partnerInfo?.icon_url} />
              <Typography className="text-white" variant="h6">
                {partnerInfo?.nick_name}
              </Typography>
            </Box>
            <Box>
              <Typography className="text-gray-400 text-sm">Lvl 12</Typography>
            </Box>
          </Box>

          {/* Chat Messages */}
          <Box className="flex-grow p-4 overflow-y-auto space-y-7">
            <MsgList onScroll={onScroll} messageList={messageList} />
          </Box>

          {/* Input Box */}
          <Box className="p-4 flex items-center space-x-3">
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Type a msgVal"
              value={msgVal}
              onChange={(e) => setMsgVal(e.target.value)}
              className="bg-[#252525] rounded-lg text-white"
              onFocus={() => (inputFocus.current = true)}
              onBlur={() => (inputFocus.current = false)}
              InputProps={{
                style: { color: 'white' },
              }}
            />
            <IconButton color="primary" onClick={handleSendMessage}>
              <Send />
            </IconButton>
          </Box>
        </Box>

        {/* Right Panel */}
        <Box className={styles.chatRight}>
          <img src={partnerInfo?.cover_url} className="w-full object-top object-cover h-[483px]" />
          <div className="flex-col justify-start items-start gap-1 flex p-6">
            <div className="text-white text-1xl font-semibold leading-[34px] truncate w-full">
              {partnerInfo?.nick_name}
            </div>
            <div className="text-zinc-300 text-sm font-normal leading-normal">
              {partnerInfo?.bio}
            </div>
          </div>
          <div className="h-[0px] opacity-20 border border-white"></div>
          <div className="flex-col justify-start items-start gap-6 flex p-6 lg:mb-0">
            <div className="text-neutral-400 text-sm font-semibold leading-normal">About me:</div>
            <div className="grid grid-cols-2">
              <div className="flex my-2">
                <div className="w-[34px] h-[34px] p-[7px] rounded-[100px] border border-white border-opacity-20 justify-center items-center">
                  <div className="w-5 h-5">
                    <img className="w-5 h-5 relative" src={ageIcon} />
                  </div>
                </div>
                <div className="ml-2">
                  <div className="text-zinc-400 text-[11px] font-normal uppercase leading-[14px]">
                    Age
                  </div>
                  <div className="text-xs text-white text-[13px] font-medium leading-tight pt-1">
                    {calculateAge(partnerInfo?.birthday)}
                  </div>
                </div>
              </div>
              <div className="flex my-2">
                <div className="w-[34px] h-[34px] p-[7px] rounded-[100px] border border-white border-opacity-20 justify-center items-center">
                  <div className="w-5 h-5">
                    <img className="w-5 h-5 relative" src={shipIcon} />
                  </div>
                </div>
                <div className="ml-2">
                  <div className="text-zinc-400 text-[11px] font-normal uppercase leading-[14px]">
                    GENDER
                  </div>
                  <div className="text-xs text-white text-[13px] font-medium leading-tight pt-1">
                    {partnerInfo?.gender}
                  </div>
                </div>
              </div>
              <div className="flex my-2">
                <div className="w-[34px] h-[34px] p-[7px] rounded-[100px] border border-white border-opacity-20 justify-center items-center">
                  <div className="w-5 h-5">
                    <img className="w-5 h-5 relative" src={occupationIcon} />
                  </div>
                </div>
                <div className="ml-2">
                  <div className="text-zinc-400 text-[11px] font-normal uppercase leading-[14px]">
                    OCCUPATION
                  </div>
                  <div className="text-xs text-white text-[13px] font-medium leading-tight pt-1">
                    {partnerInfo?.occupation}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </>
  )
}

export default ChatRoom
