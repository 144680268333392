function base64ToBlob(base64) {
  const [metadata, base64Data] = base64.split(',')

  // 解码 Base64 数据
  const byteCharacters = atob(base64Data) // Base64 解码

  const byteArrays = []

  // 将解码后的数据转为字节数组
  for (let offset = 0; offset < byteCharacters.length; offset++) {
    const byte = byteCharacters.charCodeAt(offset)
    byteArrays.push(byte)
  }

  // 创建 Uint8Array（字节数组）
  const byteArray = new Uint8Array(byteArrays)

  // 创建 Blob 对象，第二个参数为文件类型，这里是 PNG 图片
  return new Blob([byteArray], { type: 'image/png' })
}

export default base64ToBlob
