import { createSlice } from '@reduxjs/toolkit'
// import { updateLocalUserInfo } from '@/common/utils'

const initialState = {
  userInfo: {},
  isGuest: true,
  isLoading: true,
  resgisterSuccess: false,
  showLogin: false,
  showVerifyIput: true,
  showSubscribeLayer: false,
}

export const loginUserInfoSlice = createSlice({
  name: 'loginUserInfo',
  initialState,
  reducers: {
    addUserInfo: (state, action) => {
      state.userInfo = { ...action.payload }
      // updateLocalUserInfo(action.payload)
    },
    changeIsGuestStatus: (state, action) => {
      state.isGuest = action.payload
    },
    setLoadingStatus: (state, action) => {
      state.isLoading = action.payload
    },
    setRegisterSuccess: (state, action) => {
      state.resgisterSuccess = action.payload
    },
    logout: (state) => {
      state.userInfo = {}
      state.isGuest = true
      localStorage.removeItem('TOKEN')
    },
    setShowLogin: (state, action) => {
      state.showLogin = action.payload
    },
    setShowVerifyIput: (state, action) => {
      state.showVerifyIput = action.payload
    },
    setShowSubscribeLayer: (state, action) => {
      state.showSubscribeLayer = action.payload
    },
  },
})

export const loginUserInfo = (state) => state.loginUserInfo

export const {
  addUserInfo,
  changeIsGuestStatus,
  setLoadingStatus,
  logout,
  setRegisterSuccess,
  setShowLogin,
  setShowVerifyIput,
  setShowSubscribeLayer,
} = loginUserInfoSlice.actions

export default loginUserInfoSlice.reducer
