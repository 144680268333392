import Alert from '@/components/Alert'
import PasswordField from '@/components/PasswordField'
import { changePassword } from '@/service/api'
import { addUserInfo, changeIsGuestStatus } from '@/store/loginUserInfo'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import DeleteAccount from './delete'

// 自定义模糊弹窗
const BlurDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(26, 31, 46, 0.8)',
    backdropFilter: 'blur(20px)',
    WebkitBackdropFilter: 'blur(20px)',
    borderRadius: theme.spacing(2),
    maxWidth: '400px',
    width: '100%',
    padding: theme.spacing(1),
    color: '#fff',
    overflow: 'visible',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
}))

const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
]

const EditModal = forwardRef((props, ref) => {
  const { currentKey, currentValue, onSave } = props
  const [open, setOpen] = useState(false)
  const [newValue, setNewValue] = useState('')
  const oldPasswordRef = useRef(null)
  const newPasswordRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
  }))

  useEffect(() => {
    setNewValue(currentValue)
  }, [currentValue])

  const handleSave = async () => {
    if (currentKey === 'Password') {
      const oldPassword = oldPasswordRef.current.getValue()
      const newPassword = newPasswordRef.current.getValue()
      if (!oldPassword || !newPassword) {
        Alert.open('Please fill in all fields', 'warning')
        return
      }
      if (newPassword.length < 6) {
        Alert.open('Minimum 6 characters', 'warning')
        return
      }
      const res = await changePassword({
        old_password: oldPassword,
        new_password: newPassword,
      })
      if (res?.data) {
        Alert.open('Password reset successfully', 'success')
        localStorage.removeItem('TOKEN')
        dispatch(addUserInfo({}))
        dispatch(changeIsGuestStatus(true))
        navigate('/')
      }
      return
    }
    setOpen(false)
    onSave(newValue)
  }

  const editCommon = (
    <>
      {currentKey === 'Gender' ? (
        <TextField
          select
          fullWidth
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          variant="outlined"
          label="Set your gender"
          className="mb-4"
          sx={{
            mb: 3,
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: '#EC4899',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(255, 255, 255, 0.7)',
            },
          }}
        >
          {genderOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ) : currentKey === 'Password' ? (
        <div className="flex flex-col gap-4">
          <PasswordField ref={oldPasswordRef} placeholder="Old Password" />
          <PasswordField ref={newPasswordRef} placeholder="New Password" />
        </div>
      ) : (
        <TextField
          placeholder={currentKey}
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
              border: '1px solid #EC4899',
            },
            '& input': { color: 'white', width: '300px' },
          }}
        />
      )}

      <div className="flex justify-center mt-4">
        <Button className="w-full" variant="contained" color="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </div>
    </>
  )

  return (
    <BlurDialog open={open} onClose={() => setOpen(false)}>
      <button
        onClick={() => setOpen(false)}
        className="text-gray-400 hover:text-white absolute right-4 top-2"
      >
        <CloseIcon />
      </button>
      {currentKey !== 'deleteAccount' && (
        <DialogTitle>
          {currentKey === 'Password' ? 'Reset' : 'Edit'} {currentKey}
        </DialogTitle>
      )}
      <DialogContent className="w-full">
        {currentKey === 'deleteAccount' ? <DeleteAccount /> : editCommon}
      </DialogContent>
    </BlurDialog>
  )
})

export default EditModal
