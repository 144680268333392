import Notification from '@/components/Alert'
import PasswordField from '@/components/PasswordField'
import { deleteAccount } from '@/service/api'
import { addUserInfo, changeIsGuestStatus } from '@/store/loginUserInfo'
import { Button, MenuItem, TextField } from '@mui/material'
import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const reasons = [
  { value: 1, label: 'Privacy concerns' },
  { value: 2, label: 'Not satisfied with features' },
  { value: 3, label: 'Found a better alternative' },
  { value: 4, label: 'Concerns about data security' },
  { value: 5, label: `The service didn't meet my expectations` },
  { value: 6, label: 'Difficulty in navigating or using the platform' },
  { value: 7, label: 'Lack of interesting content or interactions' },
  { value: 8, label: `It's just a temporary break` },
]

const FeedbackModal = ({ open, onClose, onSubmit }) => {
  const [reason, setReason] = useState('')
  const [description, setDescription] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const passwordRef = useRef(null)

  const handleDelete = async () => {
    const password = passwordRef.current.getValue()
    if (!password) {
      Notification.open('Please enter your password', 'warning')
      return
    }
    if (password.length < 6) {
      Notification.open('Minimum 6 characters', 'warning')
      return
    }
    const res = await deleteAccount({
      deactivate_reason: reason,
      deactivate_description: description,
      password,
    })
    if (res?.data) {
      Notification.open('Account deleted successfully', 'success')
      localStorage.removeItem('TOKEN')
      dispatch(addUserInfo({}))
      dispatch(changeIsGuestStatus(true))
      navigate('/')
      onClose()
    }
  }

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-white">Help us improve</h2>
      </div>

      <p className="text-gray-300 mb-6">
        Before you leave, could you let us know why you're deleting your account? Your feedback is
        vital for us to improve.
      </p>
      <PasswordField ref={passwordRef} placeholder="Enter Password" />
      <TextField
        select
        fullWidth
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        variant="outlined"
        label="Select a reason"
        className="mb-4"
        sx={{
          mb: 4,
          mt: 3,
          '& .MuiOutlinedInput-root': {
            color: 'white',
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.23)',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(236, 72, 153, 1)',
            },
          },
          '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.7)',
          },
        }}
      >
        {reasons.map((option) => (
          <MenuItem key={option.value} value={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        multiline
        rows={4}
        fullWidth
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Description"
        variant="outlined"
        className="mb-6"
        sx={{
          mb: 3,
          '& .MuiOutlinedInput-root': {
            color: 'white',
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.23)',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(236, 72, 153, 1)',
            },
          },
        }}
      />
      <div className="flex justify-end">
        <Button className="w-full" variant="contained" color="primary" onClick={handleDelete}>
          Confirm
        </Button>
      </div>
    </>
  )
}

export default FeedbackModal
