import Header from '@/components/Header'
import Sidebar from '@/components/Sidebar'
import CheckIcon from '@mui/icons-material/Check'
import { Button } from '@mui/material'
import { useState } from 'react'

const PricingPlans = () => {
  const [selectedPlan, setSelectedPlan] = useState('')

  const plans = [
    {
      name: 'standard',
      price: '$9.99',
      features: ['100 text messages per day', 'Passion model', 'Up to 1k memory', 'Ad-free'],
    },
    {
      name: 'premium',
      price: '$19.99',
      features: [
        'Unlimited text messages',
        'Passion model',
        'Up to 8K Long-Term memory',
        'Create your own characters',
        'Ad-free',
      ],
      date: {
        start: '2023-01-01',
        end: '2025-12-31',
      },
      isSubscribe: true,
    },
  ]
  return (
    <div className="bg-[#131313] text-white min-h-screen p-6">
      <Header />
      <Sidebar />
      <div className="ml-[225px] mt-20">
        <div className="text-white text-3xl font-bold font-['Poppins'] leading-10">
          Choose your Plan
        </div>
        <div className="text-neutral-400 text-sm font-medium font-['Poppins'] leading-tight mt-4 mb-6">
          100% anonymous. You can cancel anytime.
        </div>
        <div className="grid md:grid-cols-2 gap-8 max-w-[800px] mx-auto">
          {plans.map((plan, i) => (
            <div
              key={i}
              onClick={() => setSelectedPlan(plan.name)}
              className={`p-6 bg-zinc-900 rounded-[10px] border border-[${plan.name === selectedPlan ? '#db2777' : '#282828'}]`}
            >
              <div className="font-['Poppins'] text-lg mb-4 flex items-center justify-center">
                {plan.isSubscribe && (
                  <div className="w-4 h-4 flex items-center justify-center bg-pink-600 rounded-full mr-2">
                    <CheckIcon sx={{ fontSize: 12, color: '#fff' }} />
                  </div>
                )}
                {plan.name}
              </div>
              <div className="mb-2">
                <span className="text-gray-400 line-through text-2xl">{plan.price}</span>
                <span className="text-3xl font-['Poppins'] ml-2">{plan.price}</span>
                <span className="text-gray-400 ml-2">/ month</span>
              </div>
              <div className="mb-4 text-gray-400">Bill monthly</div>
              <Button
                variant="contained"
                fullWidth
                className="font-medium"
                sx={{
                  backgroundColor: plan.isSubscribe ? 'grey.300' : 'pink.600', // 禁用时的背景色

                  '&:hover': {
                    backgroundColor: plan.isSubscribe ? 'grey.300' : 'pink.700', // 禁用时 hover 的背景色
                  },
                }}
              >
                Subscribe
              </Button>
              <div className="space-y-4 mt-6">
                {plan.features.map((feature, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <div className="w-4 h-4 flex items-center justify-center bg-pink-600 rounded-full">
                      <CheckIcon sx={{ fontSize: 12, color: '#fff' }} />
                    </div>
                    <div>{feature}</div>
                  </div>
                ))}
              </div>
              {plan.date && (
                <div className="text-gray-400 text-sm border-t border-[#282828] pt-4 mt-4 flex justify-between">
                  <span>
                    Payment date
                    <br />
                    {plan.date.start}
                  </span>
                  <span>
                    Subscribe to
                    <br />
                    {plan.date.end}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Payment Security Badges */}
        <div className="flex justify-center items-center gap-6 mt-12">
          <div className="flex items-center gap-2 text-gray-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="36"
              viewBox="0 0 30 36"
              fill="none"
            >
              <path
                d="M29.2173 8.26197L15.6131 0.168645C15.235 -0.0562149 14.7641 -0.0562149 14.3861 0.168645L0.781789 8.26197C0.418203 8.47832 0.195312 8.87024 0.195312 9.29331V13.9133C0.195312 23.6442 5.8175 32.5318 14.5663 35.919C14.8448 36.0269 15.154 36.027 15.4328 35.919C24.1603 32.5402 29.8037 23.6719 29.8037 13.9133V9.29331C29.8038 8.87017 29.5809 8.47832 29.2173 8.26197ZM20.9281 15.3035L13.4071 22.8245C12.9385 23.2932 12.1787 23.2932 11.71 22.8245L9.07051 20.1851C8.60187 19.7165 8.60187 18.9566 9.07051 18.488C9.53914 18.0194 10.299 18.0194 10.7676 18.488L12.5585 20.2789L19.231 13.6064C19.6996 13.1379 20.4594 13.1379 20.9281 13.6064C21.3968 14.0751 21.3968 14.8349 20.9281 15.3035Z"
                fill="#A4A4A4"
              />
            </svg>
            <span className="font-['Poppins'] text-sm">
              Antivirus
              <br /> Secured
            </span>
          </div>
          <div className="flex items-center gap-2 text-gray-400">
            <svg
              width="34"
              height="32"
              viewBox="0 0 34 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="_x39_22">
                <path
                  id="Vector"
                  d="M21.4662 0.0488281H10.3338C9.14061 0.0488281 7.99 0.475549 7.03841 1.39186L2.2891 6.07187C1.43874 6.92205 0.890625 8.09567 0.890625 9.42262V24.4595C0.890625 27.0765 3.02299 29.2088 5.63993 29.2088H18.5722C18.5583 29.195 18.5583 29.1811 18.5445 29.1672C16.4676 25.4288 16.7169 21.0949 16.8553 19.7796C16.9523 18.6165 17.6307 17.5919 18.6692 17.038L24.277 14.0057C24.7891 13.7288 25.3568 13.5903 25.9246 13.5903C26.0216 13.5903 26.1321 13.5903 26.2292 13.6042V4.79805C26.2292 2.18111 24.0968 0.0488281 21.4662 0.0488281ZM11.8707 20.6657H7.77229C7.01069 20.6657 6.38768 20.0427 6.38768 19.2811C6.38768 18.5057 7.01069 17.8965 7.77229 17.8965H11.8707C12.646 17.8965 13.2553 18.5057 13.2553 19.2811C13.2553 20.0427 12.646 20.6657 11.8707 20.6657ZM15.9831 14.3104H7.77229C7.01069 14.3104 6.38768 13.6873 6.38768 12.9257C6.38768 12.1504 7.01069 11.5411 7.77229 11.5411H15.9831C16.7446 11.5411 17.3678 12.1504 17.3678 12.9257C17.3678 13.6873 16.7446 14.3104 15.9831 14.3104Z"
                  fill="#A4A4A4"
                />
                <path
                  id="Vector_2"
                  d="M20.6338 19.5442C20.4263 19.655 20.2877 19.8489 20.2738 20.0843C20.1494 21.2335 19.9554 24.8612 21.6307 27.8797C21.7693 28.1289 21.9216 28.3781 22.0877 28.6135C23.0985 30.0812 24.6078 31.3135 26.8646 31.9504C34.0508 29.915 33.2063 21.8428 32.9294 20.0565C32.9017 19.835 32.7631 19.655 32.5694 19.5442L26.9339 16.4981C26.7263 16.3874 26.4632 16.3874 26.2555 16.4981L26.2277 16.5119C26.0878 16.5878 20.2688 19.7421 20.6338 19.5442Z"
                  fill="#A4A4A4"
                />
              </g>
            </svg>

            <span className="font-['Poppins'] text-left text-sm">
              Privacy in bank
              <br /> statement
            </span>
          </div>
          <svg
            width="65"
            height="40"
            viewBox="0 0 65 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer"
          >
            <rect width="65" height="40" rx="3" fill="white" />
            <path
              d="M26.95 12.8921L20.9107 27.1455H16.9706L13.9986 15.7706C13.8182 15.07 13.6613 14.8133 13.1126 14.5181C12.2167 14.0374 10.7372 13.5863 9.43549 13.3063L9.5239 12.8921H15.8664C16.6748 12.8921 17.4016 13.4244 17.5851 14.3454L19.1547 22.5931L23.0339 12.8918H26.95V12.8921ZM42.3883 22.4919C42.4041 18.73 37.1296 18.5227 37.1659 16.8422C37.1771 16.3308 37.6694 15.787 38.747 15.6482C39.2811 15.5791 40.7526 15.5263 42.4217 16.2861L43.0763 13.2635C42.1795 12.9415 41.0255 12.6316 39.5898 12.6316C35.9055 12.6316 33.3127 14.569 33.2908 17.3432C33.267 19.3952 35.1415 20.5402 36.5537 21.2221C38.0065 21.9203 38.494 22.369 38.4885 22.9936C38.4781 23.9497 37.3297 24.3718 36.2565 24.3884C34.383 24.4169 33.2958 23.8869 32.4292 23.4884L31.7537 26.611C32.6246 27.0062 34.2321 27.3511 35.8988 27.3684C39.8147 27.3684 42.3763 25.455 42.3883 22.4919ZM52.1171 27.1455H55.5645L52.5553 12.8921H49.3734C48.6579 12.8921 48.0544 13.3042 47.7873 13.9378L42.1939 27.1455H46.1079L46.8849 25.0165H51.6671L52.1171 27.1455ZM47.9581 22.0952L49.92 16.7436L51.0492 22.0952H47.9581ZM32.2757 12.8921L29.1935 27.1455H25.4662L28.5496 12.8921H32.2757Z"
              fill="#1434CB"
            />
          </svg>
          <svg
            width="65"
            height="40"
            viewBox="0 0 65 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer"
          >
            <rect width="65" height="40" rx="3" fill="white" />
            <path d="M38.51 9.51746H27.0258V30.4828H38.51V9.51746Z" fill="#FF5F00" />
            <path
              d="M27.755 20.0001C27.7532 17.9809 28.2036 15.9879 29.0721 14.1717C29.9406 12.3556 31.2045 10.764 32.768 9.51742C30.8318 7.97136 28.5065 7.00989 26.0578 6.7429C23.6092 6.47591 21.136 6.91416 18.9209 8.00759C16.7059 9.10102 14.8383 10.8055 13.5317 12.9262C12.2252 15.0469 11.5323 17.4982 11.5323 20.0001C11.5323 22.5019 12.2252 24.9532 13.5317 27.0739C14.8383 29.1946 16.7059 30.8991 18.9209 31.9925C21.136 33.086 23.6092 33.5242 26.0578 33.2572C28.5065 32.9902 30.8318 32.0288 32.768 30.4827C31.2045 29.2361 29.9406 27.6445 29.0721 25.8284C28.2036 24.0122 27.7532 22.0192 27.755 20.0001Z"
              fill="#EB001B"
            />
            <path
              d="M54.003 20.0001C54.0031 22.5018 53.3103 24.9532 52.0038 27.0739C50.6973 29.1946 48.8298 30.8991 46.6148 31.9925C44.3997 33.0859 41.9266 33.5242 39.478 33.2572C37.0294 32.9902 34.7041 32.0288 32.7679 30.4827C34.3301 29.2349 35.5929 27.643 36.4613 25.8271C37.3297 24.0113 37.7809 22.0188 37.7809 20.0001C37.7809 17.9813 37.3297 15.9888 36.4613 14.173C35.5929 12.3571 34.3301 10.7653 32.7679 9.51741C34.7041 7.97135 37.0294 7.00988 39.478 6.74289C41.9266 6.47591 44.3997 6.91418 46.6148 8.00762C48.8298 9.10106 50.6973 10.8055 52.0038 12.9262C53.3103 15.0469 54.0031 17.4983 54.003 20.0001Z"
              fill="#F79E1B"
            />
            <path
              d="M52.7508 28.2621V27.8329H52.9212V27.7455H52.4873V27.8329H52.6577V28.2621H52.7508ZM53.5932 28.2621V27.7446H53.4602L53.3072 28.1006L53.1542 27.7446H53.0211V28.2621H53.115V27.8718L53.2585 28.2083H53.3559L53.4993 27.8709V28.2621H53.5932Z"
              fill="#F79E1B"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default PricingPlans
