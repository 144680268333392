import ImageCropper from '@/components/Cropper'
import Header from '@/components/Header'
import Sidebar from '@/components/Sidebar'
import { DefaultAvatar } from '@/constants'
import { saveUserPreference } from '@/service/api'
import { addUserInfo, loginUserInfo, setShowLogin } from '@/store/loginUserInfo'
import { Button, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditModal from './editModal'

const propsMap = {
  username: 'Nickname',
  email: 'E-mail',
  password: 'Password',
  gender: 'Gender',
}

const ProfileSettings = () => {
  const { userInfo, isGuest } = useSelector(loginUserInfo)
  const { avatar, email, id, login_type } = userInfo
  const [currentKey, setCurrentKey] = useState('')
  const [realKey, setRealKey] = useState('')
  const dispatch = useDispatch()
  const editModalRef = useRef(null)
  const editUserInfo = (key) => {
    if (isGuest) {
      dispatch(setShowLogin(true))
      return
    }
    if (login_type === 'google') return
    setCurrentKey(propsMap[key])
    setRealKey(key)
    editModalRef.current.open()
  }
  const handleSave = (value) => {
    const info = { ...userInfo, [realKey]: value }
    const usedKey = ['username', 'email', 'gender', 'id']
    dispatch(addUserInfo(info))
    saveUserPreference(
      usedKey.reduce((acc, key) => {
        acc[key] = info[key]
        return acc
      }, {}),
    )
    editModalRef.current.close()
  }

  const handleAvatarChange = (avatar) => {
    dispatch(addUserInfo({ ...userInfo, avatar }))
  }

  const cancelAccount = () => {
    setCurrentKey('deleteAccount')
    editModalRef.current.open()
  }

  return (
    <div className="bg-[#131313] text-white min-h-screen p-6">
      <EditModal
        currentKey={currentKey}
        currentValue={userInfo[realKey]}
        ref={editModalRef}
        onSave={handleSave}
        email={email}
      />
      <Header />
      <div className="flex">
        <Sidebar isMobile={true} from="profile" />
        <div className="w-[350px] sm:w-[450px] md:w-[580px] lg:w-[640px] relative bg-zinc-900 rounded-[10px] border border-[#282828] mx-auto mt-20 p-6">
          <div className="grid grid-cols-[100px_1fr] gap-4 w-full lg:inline-flex p-4 max-w-full">
            {!isGuest ? (
              <>
                <Typography variant="h4" className="text-center mb-6">
                  Profile Settings
                </Typography>
                <div className="relative mb-4 md:mb-0">
                  {login_type !== 'google' && (
                    <div className="absolute top-0 right-0 z-30">
                      <ImageCropper setAvatarSrc={handleAvatarChange} />
                    </div>
                  )}
                  <img
                    src={avatar || DefaultAvatar}
                    alt="avatar"
                    className="object-cover rounded-full w-20 h-20"
                  />
                </div>
                <div className="grid grid-cols-2 gap-x-20 gap-y-4 w-full md:w-auto text-left">
                  {Object.keys(propsMap).map((key) => (
                    <div
                      className="flex flex-col"
                      key={key}
                      onClick={() => {
                        editUserInfo(key)
                      }}
                    >
                      <div className="flex items-center">
                        <Typography className="text-neutral-400 text-sm pb-1 whitespace-nowrap">
                          {propsMap[key]}
                        </Typography>
                        {login_type !== 'google' && (
                          <i className="iconfont icon-edit text-white block text-xl -mt-2 ml-1 cursor-pointer">
                            &#xe685;
                          </i>
                        )}
                      </div>
                      <Typography className="text-ellipsis whitespace-nowrap overflow-hidden max-w-40">
                        {key === 'password' ? '********' : userInfo[key]}
                      </Typography>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="text-white text-center w-full">
                Please login first.{' '}
                <span
                  className="text-[#E75275] cursor-pointer"
                  onClick={() => dispatch(setShowLogin(true))}
                >
                  Login?
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-[350px] sm:w-[450px] md:w-[580px] lg:w-[640px] mt-8 relative bg-zinc-900 rounded-[10px] border border-[#282828] mx-auto background-gradient mb-5 md:mb-10">
        <div className="md:flex px-8 py-6 flex-col md:flex-row items-center">
          <div className="flex gap-x-2 mb-4 md:mb-0">
            <div className="text-white text-base font-medium leading-relaxed">Current Plan</div>
            <div className="text-[#E75275] text-base font-medium leading-relaxed">Free</div>
          </div>
          <div className="ml-auto">
            <a
              className="relative flex justify-center w-full items-center rounded-[10px] primary-gradient text-white text-sm font-medium p-2.5 h-10"
              onClick={() => {
                if (isGuest) {
                  dispatch(setShowLogin(true))
                } else {
                  navigate('/subscribe')
                }
              }}
            >
              <Button className="w-full" variant="contained" color="primary">
                Upgrade to Premium
              </Button>
            </a>
          </div>
        </div>
      </div>
      {login_type !== 'google' && !isGuest && (
        <div className="w-[350px] sm:w-[450px] md:w-[580px] lg:w-[640px] bg-zinc-900 rounded-[10px] border border-[#282828] mx-auto mt-8 px-8 py-6 flex items-center justify-between">
          <div className="text-grey-light text-[11px] font-normal leading-normal sm:ml-2">
            If you want to permanently delete this account and all of its data.
          </div>
          <button
            className="text-ellipsis whitespace-nowrap overflow-hidden max-w-40"
            onClick={cancelAccount}
          >
            Delete account
          </button>
        </div>
      )}
    </div>
  )
}

export default ProfileSettings
