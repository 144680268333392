import CookieSettings from '@/components/CookieSetting'
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import Sidebar from '@/components/Sidebar'
import { loginUserInfo } from '@/store/loginUserInfo'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RecommendedList from './components/List'

export default function HomePage() {
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)

  const loginInfo = useSelector(loginUserInfo)
  const { isGuest, isLoading } = loginInfo

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1200)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className="flex flex-col min-h-screen bg-[#131313] text-white">
      <Header />
      <CookieSettings />
      <div className="flex flex-1 mt-20">
        <Sidebar isMobile={isMobile} />
        <main className={`flex-1 ${isMobile ? 'ml-16' : 'ml-64'}`}>
          <section className="bg-gradient-to-r from-purple-900 to-pink-900 p-12 text-center mt-6 mr-8 rounded-lg">
            <h2 className="text-4xl font-bold mb-4">Connect with AI Friends on Vibbe.AI</h2>
            <p className="mb-8">
              Experience the future of social networking with AI-powered companions
            </p>
          </section>
          <h3 className="text-2xl font-bold mt-6 text-left">Explore AI Companions</h3>
          <div className="mb-8 mr-8">
            {isLoading ? (
              <div className="px-4 py-4 md:py-6 min-h-[400px]">loading...</div>
            ) : (
              <div className="py-4 md:py-6 min-h-[400px]">
                <RecommendedList isGuest={isGuest} />
              </div>
            )}
          </div>
          <Footer />
        </main>
      </div>
    </div>
  )
}
