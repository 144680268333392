import { uploadParams } from '@/service/api'
import base64ToBlob from '@/utils/base64ToBlob'
import OSS from 'ali-oss'

let credentials

async function getTokenInfo() {
  const res = await uploadParams()
  credentials = res
}
function isCredentialsExpired(credentials) {
  if (!credentials) {
    return true
  }
  const expireDate = new Date(credentials.Expiration)
  const now = new Date()
  // 如果有效期不足一分钟，视为过期。
  return expireDate.getTime() - now.getTime() <= 60000
}
function guid() {
  return 'xxxxxx4xxxyxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

async function uploadToOSS(base64) {
  if (isCredentialsExpired(credentials)) {
    await getTokenInfo()
  }
  try {
    const client = new OSS({
      accessKeyId: credentials.access_key_id,
      accessKeySecret: credentials.access_key_secret,
      stsToken: credentials.security_token,
      bucket: credentials.bucket,
      region: credentials.region,
      endpoint: credentials.endpoint,
    })
    const blob = base64ToBlob(base64)
    let result = await client.put(`${credentials.dir}${guid()}.png`, blob)
    return result
  } catch (error) {
    console.log('error: ', error)
    return null
  }
}

export default uploadToOSS
