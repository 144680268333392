import Chat from '../pages/chat'
import Index from '../pages/index/index'
import List from '../pages/list'
import Profile from '../pages/profile'
import Subscribe from '../pages/subscribe'

const routes = [
  {
    path: `/`,
    element: <Index />,
  },
  {
    path: `/list`,
    element: <List />,
  },
  {
    path: `/chat/:characterId/:chatId`,
    element: <Chat />,
  },
  {
    path: `/profile`,
    element: <Profile />,
  },
  {
    path: `/subscribe`,
    element: <Subscribe />,
  },
]

export default routes
