import uploadToOSS from '@/utils/upload'
import { Button } from '@mui/material'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import { useRef, useState } from 'react'

const ImageCropper = ({ setAvatarSrc }) => {
  const [image, setImage] = useState(null)
  const cropperRef = useRef(null)
  const inputRef = useRef(null)

  const onSelectFile = (e) => {
    if (cropperRef.current) {
      cropperRef.current.destroy()
    }
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.onload = () => {
        setImage(reader.result)
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onCrop = async () => {
    if (cropperRef.current) {
      const croppedImage = cropperRef.current.getCroppedCanvas().toDataURL('image/jpeg')
      const res = await uploadToOSS(croppedImage)
      console.log(res)
      if (res) {
        setAvatarSrc(res.url)
      }
      cancelCrop()
    }
  }

  const onImageLoaded = (imageElement) => {
    if (imageElement) {
      cropperRef.current = new Cropper(imageElement, {
        aspectRatio: 1,
        viewMode: 1,
        autoCropArea: 1,
      })
    }
  }
  const cancelCrop = () => {
    if (cropperRef.current) {
      cropperRef.current.destroy()
    }
    inputRef.current.value = ''
    setImage(null)
  }

  return (
    <div className="absolute -left-20 top-0">
      <div className="relative w-20 h-20 overflow-hidden">
        <input
          className="absolute -left-24 top-10 z-10 cursor-pointer opacity-0"
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          ref={inputRef}
        />
        <div className="w-6 h-6 bg-white rounded-full flex items-center justify-center absolute left-12 top-12">
          <i className="iconfont text-black">&#xe614;</i>
        </div>
      </div>
      {image && (
        <div className="mt-4 absolute left-0 top-0 w-96 h-96">
          <img src={image} alt="Source" ref={onImageLoaded} style={{ maxWidth: '100%' }} />
          <div className="w-1/2 flex justify-between mx-auto mt-4">
            <Button
              variant="outlined"
              sx={{ border: '1px solid rgb(219 39 119)', height: '35px', width: '80px' }}
              onClick={cancelCrop}
            >
              Cancel
            </Button>
            <Button variant="contained" sx={{ height: '35px', width: '80px' }} onClick={onCrop}>
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ImageCropper
