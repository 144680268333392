import { googleCallBack } from '@/service/api'
import { useEffect } from 'react'

export default function GoogleLogin(props) {
  const handleCredentialResponse = async (res) => {
    const postCode = await googleCallBack({
      code: res.credential,
    })
    console.log(postCode, 'postCode')
    props.loginSuccCallback(postCode)
  }
  const onClickHandler = (res) => {
    console.log(res, 'onClickHandler')
  }
  useEffect(() => {
    if (typeof google === 'object') {
      google.accounts.id.initialize({
        client_id: '1009542111163-nifq4j0ogbvthj0i9bgq93igqcf1clog.apps.googleusercontent.com',
        ux_mode: 'popup',
        callback: handleCredentialResponse,
        // login_uri: 'http://localhost:3000/',
      })
      const loginBtn = document.getElementById('google_login')
      google.accounts.id.renderButton(loginBtn, {
        click_listener: onClickHandler,
        text: 'continue_with',
        width: 370,
        locale: 'en',
      })
      google.accounts.id.prompt((notification) => {
        console.log(notification, 'notification')
      })
    }
  }, [])
  return <div id="google_login"></div>
}
