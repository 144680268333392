import Notification from '@/components/Alert'

async function request(url, params) {
  const { method = 'GET', data = {}, isSimple = false, headers = {} } = params
  try {
    const token = localStorage.getItem('TOKEN')
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    const options = {
      method: method.toUpperCase(),
      headers: {
        ...headers,
      },
    }

    if (method.toUpperCase() === 'POST') {
      if (isSimple) {
        // 简单POST请求，使用 application/x-www-form-urlencoded
        options.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        options.body = new URLSearchParams(data).toString()
      } else {
        // 复杂POST请求，使用 application/json
        options.headers['Content-Type'] = 'application/json'
        options.body = JSON.stringify(data)
      }
    } else {
      const urlParams = new URLSearchParams(data).toString()
      url = `${url}?${urlParams}`
    }

    const response = await fetch(url, options)

    if (!response.ok) {
      throw new Error(`HTTP请求失败: ${response.status} ${response.statusText}`)
    }

    const responseData = await response.json()
    return responseData
  } catch (error) {
    console.error(error)
    Notification.open(error.message, 'error')
  }
}

export default request
