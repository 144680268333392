import Notification from '@/components/Alert'
import PasswordField from '@/components/PasswordField'
import { forgotPassword, resetPassword } from '@/service/api'
import { Button, TextField, Typography } from '@mui/material'
import { useRef, useState } from 'react'

const FindPassword = (props) => {
  const { setIsFindPassword, removeUserInfo } = props
  const [emailVal, setEmailVal] = useState('')
  const [verifyCode, setVerifyCode] = useState('')
  const passwordRef = useRef(null)

  const sendCode = async () => {
    if (!emailVal) {
      Notification.open('Please enter your email', 'warning')
      return
    }
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!pattern.test(emailVal)) {
      Notification.open('Invalid email address', 'warning')
      return
    }
    const res = await forgotPassword({ email: emailVal })
    if (res?.data) {
      Notification.open('Email sent successfully', 'success')
    }
  }
  const handleResetPassword = async () => {
    const newPassword = passwordRef.current.getValue()
    if (!emailVal || !newPassword || !verifyCode) {
      Notification.open('Please fill in all fields', 'warning')
      return
    }
    if (newPassword.length < 6) {
      Notification.open('Minimum 6 characters', 'warning')
      return
    }
    if (verifyCode.length !== 6) {
      Notification.open('Verification code is error', 'warning')
      return
    }
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!pattern.test(emailVal)) {
      Notification.open('Invalid email address', 'warning')
      return
    }
    const res = await resetPassword({
      email: emailVal,
      new_password: newPassword,
      verification_code: verifyCode,
    })
    if (res?.data) {
      removeUserInfo()
      Notification.open('Password reset successfully', 'success')
    }
  }
  return (
    <div className="flex flex-col mb-6">
      <PasswordField ref={passwordRef} placeholder="New Password" />
      <TextField
        placeholder="Email"
        value={emailVal}
        onChange={(e) => setEmailVal(e.target.value)}
        sx={{
          mt: 3,
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '8px',
          },
          '& input': { color: 'white' },
        }}
      />
      <div className="relative w-full flex flex-col ">
        <TextField
          placeholder="Verification Code"
          value={verifyCode}
          onChange={(e) => setVerifyCode(e.target.value)}
          sx={{
            mt: 3,
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
            },
            '& input': { color: 'white' },
          }}
        />
        <span
          className="absolute text-white right-5 top-9 py-1 px-3 rounded cursor-pointer bg-[#EC4899] hover:bg-[#db2777]"
          onClick={sendCode}
        >
          Send
        </span>
      </div>
      <Button
        variant="contained"
        sx={{ color: 'white', mt: 4 }}
        className="bg-pink-600 hover:bg-pink-700 hover:text-white"
        onClick={handleResetPassword}
      >
        Reset Password
      </Button>

      <Typography
        variant="body2"
        sx={{ mt: 3, color: 'white', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => setIsFindPassword(false)}
      >
        Sign in
      </Typography>
    </div>
  )
}

export default FindPassword
