import Notification from '@/components/Alert'
import { register, sendVerificationCode } from '@/service/api'
import { addUserInfo, changeIsGuestStatus, setRegisterSuccess } from '@/store/loginUserInfo'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

let downIntervalTimer = null
const VerificationBox = styled(TextField)({
  width: '50px',
  '& input': {
    textAlign: 'center',
    fontSize: '24px',
    color: '#fff',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
})

const Verify = (props) => {
  const { signupInfo } = props
  const [verificationCode, setVerificationCode] = useState(new Array(6).fill(''))
  const dispatch = useDispatch()
  const [startVerify, setVerifyStatus] = useState(false)
  const [countDown, setCountDown] = useState(60)

  const handleVerificationChange = async (index, value) => {
    const newCode = [...verificationCode]
    newCode[index] = value
    setVerificationCode(newCode)

    // 自动跳转到下一个输入框
    if (value && index < 5) {
      document.getElementById(`code-${index + 1}`)?.focus()
    }
  }
  useEffect(() => {
    const handlePaste = async (event) => {
      const text = await navigator.clipboard.readText()
      if (/^\d{6}$/.test(text)) {
        const codes = text.split('')
        setVerificationCode(codes)
        document.getElementById(`code-5`)?.focus()
      }
    }
    document.getElementById(`code-0`)?.focus()
    document.addEventListener('paste', handlePaste)
    return () => {
      document.removeEventListener('paste', handlePaste)
    }
  }, [])

  useEffect(() => {
    if (startVerify) {
      downIntervalTimer = setInterval(() => {
        setCountDown((prevCountDown) => {
          if (prevCountDown === 0) {
            clearInterval(downIntervalTimer)
            setVerifyStatus(false)
            return prevCountDown
          }
          return prevCountDown - 1
        })
      }, 1000)
    }
    return () => {
      clearInterval(downIntervalTimer)
    }
  }, [startVerify])

  const registerUser = async () => {
    const code = verificationCode.join('')
    if (code.length !== 6) {
      Notification.open('Verification code is error', 'warning')
      return
    }
    setVerifyStatus(true)
    setCountDown(60)
    const params = {
      ...signupInfo,
      username: signupInfo.email,
      verification_code: verificationCode.join(''),
    }
    if (params.verification_code.length !== 6) {
      Notification.open('Verification code is error', 'warning')
      return
    }
    try {
      const res = await register(params)
      const { data } = res || {}
      if (data) {
        const { user, imsig } = data
        dispatch(addUserInfo({ ...user, userSig: imsig }))
        dispatch(setRegisterSuccess(true))
        dispatch(changeIsGuestStatus(false))
        localStorage.setItem('TOKEN', data?.access_token)
      }
    } catch (error) {
      console.log(error)
      Notification.open('Signup failed', 'warning')
    }
  }
  const resendEmail = async () => {
    setCountDown(60)
    setVerifyStatus(true)
    const res = await sendVerificationCode({ email: signupInfo.email })
    if (res?.data) {
      Notification.open('Email sent successfully', 'success')
    }
  }

  return (
    <>
      <Typography gutterBottom sx={{ color: 'rgba(255,255,255)', marginBottom: '10px' }}>
        Enter the code sent to {signupInfo.email}
      </Typography>
      <Stack direction="row" spacing={2} justifyContent="center" mb={2}>
        {verificationCode.map((digit, index) => (
          <VerificationBox
            key={index}
            id={`code-${index}`}
            value={digit}
            sx={{
              '& input': {
                textAlign: 'center',
                fontSize: '16px',
                color: '#fff',
              },
            }}
            onChange={(e) => handleVerificationChange(index, e.target.value)}
            inputProps={{ maxLength: 1 }}
          />
        ))}
      </Stack>
      <Button
        variant="contained"
        fullWidth
        sx={{
          my: 2,
          backgroundColor: '#1a1a1a',
          color: '#fff',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: '#2a2a2a',
          },
        }}
        onClick={registerUser}
      >
        {startVerify ? (
          <>
            Resend in <span className="text-pink-500 pl-2">{countDown}s</span>
          </>
        ) : (
          'Verify'
        )}
      </Button>
      {!startVerify && countDown === 0 && (
        <Box sx={{ mt: 2, textAlign: 'center', marginBottom: '20px' }}>
          <Typography component="span" sx={{ color: 'rgba(255,255,255,0.7)' }}>
            Didn't receive the code?
          </Typography>
          <span
            onClick={resendEmail}
            className="cursor-pointer text-pink-500 pl-2 hover:text-pink-700"
          >
            Resend email
          </span>
        </Box>
      )}
    </>
  )
}

export default Verify
