import { getGuestList } from '@/service/api'
import { loginUserInfo } from '@/store/loginUserInfo'
import { addList } from '@/store/recommend'
import { calculateAge } from '@/utils'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.css'

const RecommendedList = () => {
  const [users, setUsers] = useState([])
  const loginInfo = useSelector(loginUserInfo)
  const dispatch = useDispatch()
  const { isGuest } = loginInfo
  const navigate = useNavigate()
  useEffect(() => {
    const start = async () => {
      const res = await getGuestList()
      let { data } = res || {}
      if (data?.length > 0) {
        data = data.map((item) => {
          return {
            ...item,
            age: calculateAge(item.birthday),
          }
        })
        const firstUser = data?.[0]
        setUsers(data)
        dispatch(addList(firstUser))
      }
    }
    start()
  }, [isGuest])

  return (
    <div className={styles.list}>
      {users?.map((user) => (
        <div
          key={user.id}
          className={styles.listItem}
          onClick={() => {
            dispatch(addList(user))
            navigate(`/chat/${user.id}/${user.chat_id}`)
          }}
        >
          <img
            src={user.cover_url}
            alt={user.name}
            className="rounded-lg profile-image first-image object-cover object-top h-[400px] max-h-[400px] w-full max-w-[300px]"
          />
          <div className="absolute top-2 left-2">
            {user.isNew ? (
              <div className="bg-pink-600 text-white text-xs font-bold px-2 py-1 rounded">New</div>
            ) : user.isHot ? (
              <div className="bg-pink-600 text-white text-xs font-bold px-2 py-1 rounded">Hot</div>
            ) : null}
          </div>
          <div className="z-20 absolute bottom-4 w-full px-4 md:px-6">
            <div className="flex gap-2 items-baseline pb-1">
              <div className="text-white text-base md:text-[22px] font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
                {user.nick_name}
              </div>
              {user.age ? (
                <div className="text-white/70 text-base md:text-[22px] font-semibold">
                  {user.age}
                </div>
              ) : null}
            </div>

            <p className="text-white/70 text-xs md:text-sm font-normal leading-4 break-words hyphens-auto line-clamp-3 text-left">
              {user.bio}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default RecommendedList
