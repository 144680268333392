import CloseIcon from '@mui/icons-material/Close'
import { Button, Dialog, DialogContent, IconButton, Switch } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'

const BlurDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgb(31 41 55)',
    borderRadius: '16px',
    maxWidth: '650px',
    width: '100%',
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(8px)', // 给对话框背后的内容也添加模糊效果
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}))
const CookieSettings = () => {
  const cookieIsSet = localStorage.getItem('cookieIsSet')
  const [functionalCookies, setFunctionalCookies] = useState(true)
  const [analyticalCookies, setAnalyticalCookies] = useState(false)
  const [open, setOpen] = useState(!cookieIsSet)
  const [showSetting, setShowSetting] = useState(false)
  const close = () => {
    setOpen(false)
    localStorage.setItem('cookieIsSet', 'set')
  }
  const handleSet = () => {
    if (showSetting) {
      close()
      return
    }
    setShowSetting(true)
  }
  return (
    <BlurDialog open={open} onClose={close}>
      <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={close}>
        <CloseIcon sx={{ color: 'white' }} />
      </IconButton>
      <DialogContent>
        {showSetting ? (
          <div className=" text-white p-6">
            <h2 className="text-xl font-bold mb-4">Cookie Settings</h2>
            <p className="mb-4">We do not use marketing cookies.</p>

            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="font-semibold">Functional Cookies</h3>
                <p className="text-sm text-gray-400">
                  Functional cookies are essential to ensure that our website works properly.
                </p>
              </div>
              <Switch
                checked={functionalCookies}
                onChange={() => setFunctionalCookies(!functionalCookies)}
                color="primary"
              />
            </div>

            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="font-semibold">Analytical Cookies</h3>
                <p className="text-sm text-gray-400">
                  Analytical cookies give us a valuable insight to the usage of our website. This
                  allows us to continue to improve the services we offer you.
                </p>
              </div>
              <Switch
                checked={analyticalCookies}
                onChange={() => setAnalyticalCookies(!analyticalCookies)}
                color="primary"
              />
            </div>

            <p className="text-sm text-gray-400 mb-4">
              Read about how we use cookies in our{' '}
              <a href="#" className="text-blue-400 underline">
                privacy and cookie statement
              </a>
            </p>
          </div>
        ) : (
          <div className="text-white p-6 ">
            We use technology, such as cookies, to improve site functionality, personalize content,
            and analyze web traffic. We also restrict this site to users over the age of 18. By
            clicking "accept" you agree you are of age and that you allow us to share information
            that we collect with our third party advertising, analytics, and service partners and
            use your information for such purposes as outlined in our{' '}
            <a href="#" className="text-blue-400 underline">
              privacy and cookie statement
            </a>
            .
          </div>
        )}
        <div className="flex justify-between w-96 mx-auto">
          <Button variant="contained" color="primary" className="mr-2" onClick={handleSet}>
            {showSetting ? 'Save Settings' : 'Manage Consent preferences'}
          </Button>

          <Button variant="contained" color="primary" onClick={close}>
            Accept All
          </Button>
        </div>
      </DialogContent>
    </BlurDialog>
  )
}

export default CookieSettings
