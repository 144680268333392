import request from './request'

const BASE_URL = '/api/model-server/v1'
export const login = (params) =>
  request(`${BASE_URL}/login`, {
    method: 'POST',
    data: params,
  })

export const sendVerificationCode = (params) =>
  request(`${BASE_URL}/send-verification-code`, {
    method: 'POST',
    data: params,
  })

export const register = (params) =>
  request(`${BASE_URL}/register`, {
    method: 'POST',
    data: params,
  })

export const getUserInfo = () =>
  request(`${BASE_URL}/me`, {
    method: 'GET',
  })

export const prefenceTags = () =>
  request(`${BASE_URL}/get_all_preference`, {
    method: 'GET',
  })

export const getRecommendedUsers = (uid) =>
  request(`${BASE_URL}/recommendlist/${uid}`, {
    method: 'GET',
  })

export const saveUserPreference = (params) =>
  request(`${BASE_URL}/set_user_info`, {
    method: 'POST',
    data: params,
  })

export const followUser = (params) =>
  request(`${BASE_URL}/allow_users`, {
    method: 'POST',
    data: params,
  })
export const uploadParams = () =>
  request(`${BASE_URL}/oss/get_sts_token`, {
    method: 'GET',
  })

export const googleCallBack = (params) =>
  request(`${BASE_URL}/google/login`, {
    method: 'POST',
    data: params,
  })

export const forgetPassword = (params) =>
  request(`${BASE_URL}/forgot-password`, {
    method: 'POST',
    data: params,
  })

export const getGuestList = () =>
  request(`${BASE_URL}/recommended`, {
    method: 'get',
  })

export const changePassword = (params) =>
  request(`${BASE_URL}/change-password`, {
    method: 'POST',
    data: params,
  })
// 获取用户信息
export const getChatPartnerInfo = (params) =>
  request(`${BASE_URL}/characters/info`, {
    method: 'GET',
    data: params,
  })

//api/model-server/v1/deactivate

export const deleteAccount = (params) =>
  request(`${BASE_URL}/deactivate`, {
    method: 'POST',
    data: params,
  })

//forget password
export const forgotPassword = (params) =>
  request(`${BASE_URL}/forgot-password`, {
    method: 'POST',
    data: params,
  })

//reset password
export const resetPassword = (params) =>
  request(`${BASE_URL}/reset-password`, {
    method: 'POST',
    data: params,
  })
